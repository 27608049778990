<template>
  <div>
    <SignInData @getRecord="getRecord" v-if="!isRecord"></SignInData>
    <SignInRecord @getData="getData" v-else></SignInRecord>
  </div>
</template>

<script>
export default {
  name: 'signIn',
  components: {
    "SignInData": (resolve) => require(["./signInData"], resolve),
    "SignInRecord": (resolve) => require(["./signInRecord"], resolve),
  },
  data () {
    return {
      isRecord: false,
    }
  },
  mounted () {
    this.isRecord = false
  },
  methods: {
    //记录
    getRecord () {
      this.isRecord = true
      this.$parent.$parent.getCustomCrumb([
        // { title: '签到活动', to: '/activity/signIn' },
        { title: '签到记录' }
      ])
    },
    //数据
    getData () {
      this.isRecord = false
      this.$parent.$parent.getCustomCrumb([
        { title: '签到活动' }
      ])
    },
  }
}
</script>

<style scoped>
</style>